var sectionCtgryNo = givenMain.sectionCtgryNo;
if (sectionCtgryNo === givenMain.displayEnumOTLTCTG) {
  sectionCtgryNo = '';
}
if(givenMain.ctgryMainYn == 'Y'){
	sectionCtgryNo = '';
}
CMN_MyWishInitAll();//#116680. 2020-10-20 상품목록 찜 기능
function initCnr() {
    givenMain.cnr.forEach(function (cnr) {
        if (cnr.isTopSeller) {
          setTimeout(function () {
            if ($("#topSeller" + cnr.cnrDspCnrDspCnrDscr).length > 0) {
              topSellerLoading(givenMain.brndId, givenOption.language, sectionCtgryNo, givenMain.firstDspCnrSetDscr, cnr.cnrDspCnrDspCnrDscr);
            }
          }, 0);
        }

        if (cnr.isNewIn) {
          setTimeout(function () {
            if ($("#newIn" + cnr.cnrDspCnrDspCnrDscr).length > 0) {
              newInLoading(givenMain.brndId, givenOption.language, sectionCtgryNo, givenMain.firstDspCnrSetDscr, cnr.cnrDspCnrDspCnrDscr);
            }
          }, 0);

        }

        if (cnr.isClickRank) {
            setTimeout(function () {
                if ($("#ranking" + cnr.cnrDspCnrDspCnrDscr).length > 0) {
                    rankingLoading(cnr.cnrDspCnrDspCnrDscr);
                }
            }, 0);
        }

        if (cnr.isSaleRank) {
            setTimeout(function () {
                if ($("#ranking" + cnr.cnrDspCnrDspCnrDscr).length > 0) {
                    rankingLoading(cnr.cnrDspCnrDspCnrDscr);
                }
            }, 0);
        }
        if (cnr.isDiver) {
            setTimeout(function () {
                if ($("#diver" + cnr.cnrDspCnrDspCnrDscr).length > 0) {
                    setMainObserver("diverDIVER_STYLE_BANNER");
                }
            }, 0);
        }
    });

    //<!-- #166989 [퍼블_MO] 컨텐츠 팝업 UI 변경 및 BO 팝업관리 개선 -->
    if($('#contentsPopupMoCnr').length > 0){

        $(document).ready(function () {
            var exe_mainContPopup = function () {
                if ($('.mainContPopup .swiper-slide').length < 2) {
                    $('.mainContPopup .swiper-pagination').remove();
                    return false;
                }
                var mainContPopup = new Swiper(".mainContPopup .swiper", {
                    direction: "horizontal",
                    slidesPerView: 1,
                    spaceBetween: 0,
                    loop: $("#contentsPopupMoCnr .swiper-slide").length > 1,
                    autoplay: {
                        delay: 4000,
                        disableOnInteraction: false
                    },
                    pagination: {
                        el: ".mainContPopup .swiper-pagination",
                        type: "fraction"
                    },
                    on: {
                        init: function (swiper) {
                            lazyLoadSwiper.init(this);
                        }
                    }
                });
            };

            let contentPopupCloseToDay = getCookie('contentPopupCloseToDay');
            if (!contentPopupCloseToDay) {
                if ($(".mainContPopup").length > 0) {
                    exe_mainContPopup();
                }
                var that = $('.modal-layer.mainContPopup').openLayer({top: 'auto'});
                action_logging({
                    'tagStep': 'popupDisplay',
                    'tagArea': 'contents',
                    'tagPage': location.pathname.replace(/^\//, '')
                });
                $(that).prev('.modal-dim').on('click', function () {
                    action_logging({
                        'tagStep': 'popupClick',
                        'tagArea': 'contents',
                        'tagPage': location.pathname.replace(/^\//, ''),
                        'tp': 'close'
                    });
                });
            }
            $("#contentPopupCloseToDay").on('click', function () {
                setCookie('contentPopupCloseToDay', 'Y', 1);
                $('#contentsPopupMoCnr').closest('.modal-layer').closeLayer();
                action_logging({
                    'tagStep': 'popupClick',
                    'tagArea': 'contents',
                    'tagPage': location.pathname.replace(/^\//, ''),
                    'tp': 'closeToday'
                });
            });

        });
    }
}




function goImgSearchGod(godNo) {
  //window.localStorage.setItem("URL_imgsearch_clicked", document.URL);
  location.href = "/public/search/imgsearch/" + godNo + "/similarGoodslist";
}
//  app download layer
//  몰 MAIN / 국문일 경우 노출
if (givenOption.lnbCategorySetBrndShopId === "" && givenOption.isKo) {
  $(document).ready(function () {
    $("#naverCheckbox").click(function () {
      popOut();
    });
  });

  // 팝업 닫기
  function popOut() {
    // app download layer 24시 기준 재노출
    setCookie('todayAppDownFlag', 'Y', 1);
  }
}

// app download layer
// 앱푸쉬(광고성) 수신동의 프로모션

if (givenMain.appPushPrm) {
  $(document).ready(function () {
    if (setMobileAppFlag()) {	//SSF Shop App 인 경우
          if(/ssfshop_app/gi.test(navigator.userAgent.toLowerCase())) {   //SSF Shop App 인 경우
              getCheckAllSsfNtcnYn();
          } else if(/beanpole_app/gi.test(navigator.userAgent.toLowerCase())) {   //빈폴 Shop App 인 경우
              getCheckAllBeanpoleNtcnYn();
          } else if(/brooks_app/gi.test(navigator.userAgent.toLowerCase())) {   //브룩스 Shop App 인 경우
              getCheckAllBrooksNtcnYn();
          } else if(/8seconds_app/gi.test(navigator.userAgent.toLowerCase())) {   //8S Shop App 인 경우
              getCheckAllNtcnYn();
          }
      }
  });

    //WEB -> APP SSF 앱푸시 수신동의 여부 조회 처리요청
    function getCheckAllSsfNtcnYn(){
        //TO-DO : 앱환경에서 주석해제
      var userAgent = navigator.userAgent;
      var appVersionIndex = userAgent.indexOf("app_version_");
      var version = userAgent.substring(appVersionIndex + 12, appVersionIndex + 16).replace("/", "");
      if(IS_IOS) {
        if (/ssfshop_app/gi.test(navigator.userAgent) && version < 1.80) {
          loadIntentAction("app://getbarcode_ssf_event");
        } else if (/ssfshop_app/gi.test(navigator.userAgent) && version >= 1.80) {
          window.webkit.messageHandlers.getbarcode_ssf_event.postMessage({});
        } else {
          loadIntentAction("app://getbarcode_ssf_event");
        }
      } else {
        loadIntentAction("app://getbarcode_ssf_event");
      }

        //개발자 테스트 코드
       /* var device_id = 'e45a542524f99315';
        $.ajax({
            type 	 : "GET",
            dataType : "json",
            data : {appId : device_id},
            async : false,
            url		 : '/public/interface/getbarcodeSsfEvent.json',
            success  : function(data) {
                fn_getBarcodeSsfEvent(data);
            },
            error: function( xhr, status, err ) {
                alert(xhr.responseText);
            }
        });*/
    }

    //WEB -> APP 빈폴 앱푸시 수신동의 여부 조회 처리요청
    function getCheckAllBeanpoleNtcnYn(){
        //TO-DO : 앱환경에서 주석해제
      var userAgent = navigator.userAgent;
      var appVersionIndex = userAgent.indexOf("app_version_");
      var version = userAgent.substring(appVersionIndex + 12, appVersionIndex + 16).replace("/", "");
      if(IS_IOS) {
        if (/beanpole_app/gi.test(navigator.userAgent) && version < 2.70) {
          loadIntentAction("app://getbarcode_beanpole_event");
        } else if (/beanpole_app/gi.test(navigator.userAgent) && version >= 2.70) {
          window.webkit.messageHandlers.getbarcode_beanpole_event.postMessage({});
        } else {
          loadIntentAction("app://getbarcode_beanpole_event");
        }
      } else {
        loadIntentAction("app://getbarcode_beanpole_event");
      }

        //개발자 테스트 코드
        /* var device_id = 'e45a542524f99315';
         $.ajax({
             type 	 : "POST",
             dataType : "json",
             data : {appId : device_id},
             async : false,
             url		 : '/public/interface/getbarcodeBeanpoleEvent.json',
             success  : function(data) {
                 fn_getBarcodeBeanpoleEvent(data);
             },
             error: function( xhr, status, err ) {
                 alert(xhr.responseText);
             }
         });*/
    }

    //WEB -> APP 8S 앱푸시 수신동의 여부 조회 처리요청
    function getCheckAllNtcnYn(){
        //TO-DO : 앱환경에서 주석해제
      var userAgent = navigator.userAgent;
      var appVersionIndex = userAgent.indexOf("app_version_");
      var version = userAgent.substring(appVersionIndex + 12, appVersionIndex + 16).replace("/", "");
        if(IS_IOS) {
          if(/8seconds_app/gi.test(navigator.userAgent.toLowerCase()) && version < 4.70) {
            loadIntentAction("app://getBarcodeEvent");
          }else if(/8seconds_app/gi.test(navigator.userAgent) && version >= 4.70) {
            window.webkit.messageHandlers.getBarcodeEvent.postMessage({});
          }else {
            loadIntentAction("app://getBarcodeEvent");
          }
        }else{
            loadIntentAction("app://getbarcode_8sec_event");
        }
        //개발자 테스트 코드
        /*var device_id = 'e45a542524f99315';
        $.ajax({
            type 	 : "GET",
            dataType : "json",
            data : {appId : device_id},
            async : false,
            url		 : '/public/interface/getbarcodeEvent.json',
            success  : function(data) {
                fn_getBarcodeEvent(data);
            },
            error: function( xhr, status, err ) {
                alert(xhr.responseText);
            }
        });*/
    }

    //WEB -> APP 브룩스 앱푸시 수신동의 여부 조회 처리요청
    function getCheckAllBrooksNtcnYn(){
        //TO-DO : 앱환경에서 주석해제
      var userAgent = navigator.userAgent;
      var appVersionIndex = userAgent.indexOf("app_version_");
      var version = userAgent.substring(appVersionIndex + 12, appVersionIndex + 16).replace("/", "");
      if(IS_IOS) {
        if (/brooks_app/gi.test(navigator.userAgent) && version < 1.50) {
          loadIntentAction("app://getbarcode_brooks_event");
        } else if (/brooks_app/gi.test(navigator.userAgent) && version >= 1.50) {
          window.webkit.messageHandlers.getbarcode_brooks_event.postMessage({});
        } else {
          loadIntentAction("app://getbarcode_brooks_event");
        }
      } else {
        loadIntentAction("app://getbarcode_brooks_event");
      }

        //개발자 테스트 코드
        /*var device_id = 'e45a542524f99315';
        $.ajax({
            type 	 : "GET",
            dataType : "json",
            data : {appId : device_id},
            async : false,
            url		 : '/public/interface/getbarcodeBrooksEvent.json',
            success  : function(data) {
                fn_getBarcodeBrooksEvent(data);
            },
            error: function( xhr, status, err ) {
                alert(xhr.responseText);
            }
        });*/
    }

  //APP -> WEB 앱푸시 수신동의 여부 조회 처리요청 callback
  function fn_getBarcodeSsfEvent(jsonData) {
      var deviceId = jsonData.deviceId;
      setCookie("DEVICE_ID", deviceId);
  }

    //APP -> WEB 빈폴 앱푸시 수신동의 여부 조회 처리요청 callback
    function fn_getBarcodeBeanpoleEvent(jsonData) {
        var deviceId = jsonData.deviceId;
        setCookie("DEVICE_ID", deviceId);
    }

    //APP -> WEB 8S 앱푸시 수신동의 여부 조회 처리요청 callback
    function fn_getBarcodeEvent(jsonData){
        var deviceId = jsonData.deviceId;
        setCookie("DEVICE_ID", deviceId);
    }

    //APP -> WEB 브룩스 앱푸시 수신동의 여부 조회 처리요청 callback
    function fn_getBarcodeBrooksEvent(jsonData){
        var deviceId = jsonData.deviceId;
        setCookie("DEVICE_ID", deviceId);
    }

    function fn_useBarcodeSsfEvent(jsonData){ //IOS는 CALL BACK 이 잘못개발됨
        fn_getBarcodeSsfEvent(jsonData);
    }

    function fn_useBarcodeBeanpoleEvent(jsonData){ //IOS는 CALL BACK 이 잘못개발됨
        fn_getBarcodeBeanpoleEvent(jsonData);
    }

    function fn_useBarcodeEvent(jsonData){ //IOS는 CALL BACK 이 잘못개발됨
        fn_getBarcodeEvent(jsonData);
    }

    function fn_useBarcodeBrooksEvent(jsonData){ //IOS는 CALL BACK 이 잘못개발됨
        fn_getBarcodeBrooksEvent(jsonData);
    }

}

function getDiffDaysForCookieEndDt(yyyymmdd) {
  var defaultDays = 365;
  if( !yyyymmdd || yyyymmdd.length !== 8) {
    return defaultDays;
  }

  var sYear = yyyymmdd.substring(0,4);
  var sMonth = yyyymmdd.substring(4,6);
  var sDate = yyyymmdd.substring(6,8);

  if( sYear === 9999){
    return defaultDays;
  }

  if( Number(sMonth) < 1 || Number(sMonth) > 12){
    return defaultDays;
  }

  if( Number(sDate) < 1 || Number(sDate) > 31){
    return defaultDays;
  }

  var today = new Date();
  var endDt = new Date(Number(sYear), Number(sMonth)-1, Number(sDate));

  var diffDays = Math.ceil((endDt.getTime() - today.getTime()) / (1000 * 3600 * 24));
  if( diffDays < 1 || diffDays > 730){
    return defaultDays;
  }

  return diffDays + 1;
}

function isElementInViewport (el) {

    // Special bonus for those using jQuery
    if (typeof jQuery === "function" && el instanceof jQuery) {
        el = el[0];
    }

    var rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
        rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
    );
}

function topBnfCookie(mainTopbannerId, closeId, dspEndDt) {
  if ($('#chk_' + closeId).prop('checked')) {
    setCookie(closeId, 'Y', getDiffDaysForCookieEndDt(dspEndDt));
  }
  $('#' + closeId).remove();

  if( $("#"+mainTopbannerId).find(".topbanner-box").length < 1 ){
    $('#' + mainTopbannerId).remove();
  }
}

function goLogin(flag) {
  if (flag == 'm') {
    location.href = "/secured/mypage/certifyMember";
  } else {
    location.href = "/public/member/login?loginTarget=" + encodeURIComponent(location.href);
  }
}

// <!-- SR#147549.메인 Top Brand 영역 아웃링크(토리버치) 클릭 로깅 추가 요청  -->
function fnClickBstBrnd(url, utag){
    if(url.includes('toryburch.co.kr')) {
        action_logging({outlink:"toryburch&"+utag});
    }
}


// <!-- 추천 상품이 노출되는 경우 추천업체 및 추천 상품 리스트를 WiseLog에 로깅하기 위해 n_click_logging으로 로깅.. -->
if (!!givenOption.utag_base) {
  n_click_logging('impression', '', givenOption.utag_base + '$tp:im$attr_prds:' + givenOption.utag_prds);
}
if (!!givenOption.utag_base) {
  n_click_logging('impression', '', givenOption.utag_base + '$tp:im$attr_prds:' + givenOption.utag_prds);
}

if (givenOption.isAbTest) {
  n_click_logging('display_ab_test', '',
    'ab_test_sn:' + givenOption.dtoAbTestSn
    + '$ab_rev_sn:' + givenOption.dtoRevSn
    + '$ab_rev_trn_sn:' + givenOption.dtoRevCpstTurn);
}
//<!-- 개인화 캠페인이 적용된 페이지의 경우 n_click_logging으로 로깅 -->
if (givenMain.isCmpg) {
  givenMain.cmpgLogInfo.forEach(function (log) {
    n_click_logging('display_cmpg', '', log);
  });
}

//#76544 연말정산 이벤트 - (main alert 개발건)
if (givenMain.hasMainOrdCpnEvtNo) {
	setTimeout(function () {
		try {
			// 최초 1번만 연말정산 쿠폰 발급내용 노출함
			if(getCookie('mainOrderCpnFlag') != 'Y') {
				setCookie("mainOrderCpnFlag", "Y", 90);
				commonAlert2("<span style='display:block; text-align: center;'>고객님께 감사의 마음을 담아 준비한<br>룰렛 이벤트로 100% 혜택을 받으세요!</span>", function() {
					location.href = "/public/inflow/partnerGate/serviceId/24010?nextUrl=/event/" + givenMain.mainOrdCpnEvtNo + "/view";
				}, true);
			}
    	} catch(e) {}
	}, 0);
}

//#138095 쿠폰 지급 알람 개발 건
if(givenMain.eventCouponAlertYn){
    try{
        if(getCookie('eventCouponAlertYn') != 'Y'){
            $.ajax({
                url: '/public/promotion/event/eventCouponAlertYn.json',
                type: 'GET',
                success: function (data) {
                    if(data.moveUrlMb && data.alertMsg){
                        setCookie("eventCouponAlertYn", "Y", 40);

                        commonConfirm2("<span style='display:block; text-align: center;'>"+data.alertMsg+"</span>", function() {
                          location.href = data.moveUrlMb;
                        }, true);
                    }
                },
                error: function (data) { }
            });
        }
    }catch (e) { }
}

$('#lstPieceMore').click(function(e) {
  lstPieceLoading();
  e.preventDefault();
});

// tab 카테고리 배너 탭 클릭시
function tabCtgryBannerClick(_this){
    var cnrSetSn = $(_this).find("input[name='cnrSetSn']").val();
    var cnrDscr = $(_this).find("input[name='cnrDscr']").val();
    var tabCount = parseInt($(_this).find("input[name='tabCount']").val()) + 1;
    var mainPageNm = $("input[name='mainPageNm']").val();

    $.ajax({
        type : "GET",
        url : "/getTabDataMore",
        data : {"cnrSetSn" : cnrSetSn, "cnrDscr" : cnrDscr, "tabCount" : tabCount, "tmplatScKey" : mainPageNm},
        success : function (data) {
            $("#tabCList").empty();
            $("#tabCList").html(data);

            $(_this).attr("aria-selected","true");
            $(_this).siblings().attr("aria-selected","false");
        
            lazyLoad.init();
        }
    });
}

// tab 다이버 배너 탭 클릭시
function tabDiverBannerClick(_this){
    var setNm = $(_this).find("input[name='setNm']").val();
    var cnrDscr = $(_this).find("input[name='cnrDscr']").val();
    var tabCount = parseInt($(_this).find("input[name='endTabCnt']").val()) + 1;
    var mainPageNm = $("input[name='mainPageNm']").val();
    var cnrSetSn = $(_this).find("input[name='cnrSetSn']").val();

    if(!isNaN(tabCount)) {
        $.ajax({
            type: "GET",
            url: "/getTabDataMore",
            data: {
                "cnrSetSn": cnrSetSn,
                "setNm": setNm,
                "cnrDscr": cnrDscr,
                "tabCount": tabCount,
                "tmplatScKey": mainPageNm
            },
            success: function (data) {
                $(".storyList.column3").empty();
                $(".storyList.column3").html(data);

                $(_this).attr("aria-selected", "true");
                $(_this).siblings().attr("aria-selected", "false");

                lazyLoad.init();
            }
        });
    }
}

// [SSF] MO_2022_TOP 브랜드 더보기
var topBrndCurrentPage = $("input[name='brndFirstPage']").val();
var topBrndList = [];
function reloadBrandMore(cnrDscr,brandCnrSetSize){
    var firstPage = $("input[name='brndFirstPage']").val();
    var mainPageNm = $("input[name='mainPageNm']").val();
    var brndCnrSetSnLength = $("input[name='topBrndSetSn[]']").length;
    var brndCnrSetSn = "";
    for(var i=0;i<brndCnrSetSnLength;i++){
        topBrndList[i] = $("input[name='topBrndSetSn[]']")[i].value;
    }

    if((firstPage == 0 && topBrndCurrentPage == brandCnrSetSize-1) || (firstPage == 1 && topBrndCurrentPage == brandCnrSetSize)){
        topBrndCurrentPage = Number($("input[name='brndFirstPage']").val());
        if(firstPage==1){
            brndCnrSetSn = topBrndList[topBrndCurrentPage-1];
        } else {
            brndCnrSetSn = topBrndList[topBrndCurrentPage];
        }
    }else{
        topBrndCurrentPage++;
        if(firstPage==1){
            brndCnrSetSn = topBrndList[topBrndCurrentPage-1];
        } else {
            brndCnrSetSn = topBrndList[topBrndCurrentPage];
        }
    }

    $.ajax({
        type : "GET",
        url : "/getTabDataMore",
        data : {"cnrSetSn" : brndCnrSetSn, "cnrDscr" : cnrDscr, "index" : topBrndCurrentPage, "tabCount" : topBrndCurrentPage+1, "tmplatScKey" : mainPageNm},
        success : function (data) {
            $("#topBrandList").empty();
            $("#topBrandList").html(data);

            if((firstPage == 0 && topBrndCurrentPage == brandCnrSetSize) || (firstPage == 1 && topBrndCurrentPage-1 == brandCnrSetSize)){
                $("#brndReload").find("span").text("1/"+brandCnrSetSize);
            }else{
                if(firstPage == 0){
                    $("#brndReload").find("span").text((topBrndCurrentPage+1)+"/"+brandCnrSetSize);
                }else{
                    $("#brndReload").find("span").text(topBrndCurrentPage+"/"+brandCnrSetSize);
                }
            }
                
            lazyLoad.init();
        }
    });
}

// [SSF] MO_2022_룩북 더보기
function reloadLookbookdMore(cnrDscr,lookbookCnrSetSize){
    var mainPageNm = $("input[name='mainPageNm']").val();
    var lookbookCurrentPage = Number($("input[name='lookbookCurrentPage']").val());
    
    // 다음 코너세트 랜덤 뽑기
    var nextSetSn = shuffleLookBook();

    $.ajax({
        type : "GET",
        url : "/getTabDataMore",
        data : {"cnrDscr" : cnrDscr, "cnrSetSn" : nextSetSn, "tabCount" : lookbookCurrentPage, "tmplatScKey" : mainPageNm},
        success : function (data) {
            $(".lookbookItem").empty();
            $(".lookbookItem").html(data);
            
            if(lookbookCurrentPage == lookbookCnrSetSize){
                $("input[name='lookbookCurrentPage']").val(1);
                $("#lookbookReload").find("span").text("1/"+lookbookCnrSetSize);
            }else{
                $("input[name='lookbookCurrentPage']").val(lookbookCurrentPage+1);
                $("#lookbookReload").find("span").text((lookbookCurrentPage+1)+"/"+lookbookCnrSetSize);
            }
        
            lazyLoad.init();
        }
    });
}

var lookbookRandomIdx;
var lookbookIdx;
var lookbookList = [];
var lookbookCopy = [];
function shuffleLookBook(){
    var cnrSetSnLength = $("input[name='lookbookSetSn[]']").length;
    var nextSetSn;
    
    // 룩북 코너 세트 번호 저장
    for(var i=0;i<cnrSetSnLength;i++){
        lookbookList[i] = $("input[name='lookbookSetSn[]']")[i].value;
    }
    
    // 룩북 더보기 첫 클릭 or 코너세트 더보기 클릭으로 모두 불러온 후
    if(lookbookCopy.length == 0){
        lookbookCopy = lookbookList.slice();
    }
    
    // 더보기 처음 클릭시 index 0번째 코너 번호 제외(화면에 보이는)
    if(lookbookRandomIdx == undefined){
        lookbookCopy.splice(0,1);
    }
    
    lookbookRandomIdx = Math.floor(Math.random() * lookbookCopy.length);
    // 다음에 노출 될 코너세트
    nextSetSn = lookbookCopy[lookbookRandomIdx];
    
    // 다음에 노출 될 코너 번호 lookbookCopy에서 제외
    lookbookCopy.splice(lookbookRandomIdx,1);
 
    return nextSetSn;
}

function lookbookOtherImg(index,_this){
    var captionIdx = "caption"+index;

    $("[data-index="+captionIdx+"]").css("display","");
    $("[data-index*='caption']").not("[data-index="+captionIdx+"]").css("display","none");
}

function updateMyBrand(id, myBrndCount, _this){

	if(!givenBrndCtgry.isUser){
          event.stopImmediatePropagation();
		var url = location.href;
		location.href = "/public/member/login?loginTarget="+encodeURIComponent(url);
		return;
	}

    var currentCnt = parseInt(myBrndCount); // 현재 브랜드 하트 갯수
    var actionType = "";
    var	url = "";

    if($(_this).attr('aria-pressed') == "true"){ // 찜 목록에서 해제
        actionType = "D";
        url = "/deleteMyBrand.json";
    }else{ // 찜 목록에 추가
        actionType = "C";
        url = "/addMyBrand.json";
    }

    $.ajax({
        type: "POST",
        url: url,
        data: "brndId=" + id,
        beforeSend: function (request){
            var csrfToken = $("meta[name='_csrf']").attr("content");
            var csrfName = $("meta[name='_csrf_header']").attr("content");
            request.setRequestHeader(csrfName, csrfToken);
        },
        success: function(data) { // 찜 해제
            var html = '';
            if(actionType === "D"){
                currentCnt = currentCnt-1;
                if(currentCnt > 999){
                    html += '<button class="like white" aria-pressed="false" aria-label="좋아요" onclick="javascript:updateMyBrand(\''+id+'\',\''+currentCnt+'\', this);"></button>999+';
                }else{
                    html += '<button class="like white" aria-pressed="false" aria-label="좋아요" onclick="javascript:updateMyBrand(\''+id+'\',\''+currentCnt+'\', this);"></button>'+currentCnt;
                }
                $(".brandIntro .gpa").html(html);
            }else if(actionType === "C" && data.result > 0){  // 찜하기
                currentCnt = currentCnt+1;
                if(currentCnt > 999){
                    html += '<button class="like white" aria-pressed="true" aria-label="좋아요" onclick="javascript:updateMyBrand(\''+id+'\',\''+currentCnt+'\', this);"></button>999+';
                }else{
                    html += '<button class="like white" aria-pressed="true" aria-label="좋아요" onclick="javascript:updateMyBrand(\''+id+'\',\''+currentCnt+'\', this);"></button>'+currentCnt;
                }
                $(".brandIntro .gpa").html(html);
            }else{
                console.log("오류가 발생하였습니다.");
            }
            toggleButton_like();
        },
        error: function (e) {
            console.log(e.responseText);
        }
    });
}

// [SSF]  MO_2022_브랜드 룩북
var lookbookSet = [];
// [SSF] MO_2022_혜택 띠배너
var sashBanner = [];
// [SSF] MO_2022_상품리스트(더보기) 01-04
var categoryBrandSet = [];


// #174094 [SSF] MO_2022_상품리뷰코너 관련 js START
const reviewShowCount = 3;
/**
 * [SSF] MO_2022_상품리뷰코너
 * [button] 상품 리뷰 탭 선택
 */
function moveReviewTab(obj){
    //선택한 탭 ID
    var godReviewTabId = $(obj).parent().attr("godReviewTabId");
    $("#ul_reviewShow").empty();
    var godReviewlist = $("#"+godReviewTabId).children("li");
    for(let i=0; i < reviewShowCount; i++){
        if(i < godReviewlist.length){
            $("#ul_reviewShow").append($(godReviewlist[i])[0].outerHTML);
        }
    }

    if(godReviewlist.length > reviewShowCount){
        $("#btn_reloadGodEvlList").show();
    }else{
        $("#btn_reloadGodEvlList").hide();
    }
    lazyLoad.init();
}
/**
 * [SSF] MO_2022_상품리뷰코너
 * [button] 리뷰 더보기
 */
function moveReviewPage(reviewSn){
    var lastGodReviewlist = $("#"+reviewSn).children().not("[style='display: none;']").last();
    var lastShowIdx = parseInt($(lastGodReviewlist).attr("godEvlIndex"));

    //현재 선택된 탭 ID
    var godReviewTabId = $("li[godReviewTabId^='review_'][aria-selected='true']").attr("godReviewTabId");
    //현재 선택된 탭의 li 리스트
    var godReviewlist = $("#"+godReviewTabId).children("li");
    //기존에 노출된 상품리뷰 숨김
    $("#"+godReviewTabId).children().hide();
    //더보기 해당 li 노출되도록 (순환 노출)
    //ex:8개 있을 때 >> [0,1,2], [3,4,5], [6,7,0], [1,2,3]
    if(godReviewlist.length > 3){
        var i = 0;
        for(var j=0; j < reviewShowCount; j++){
            i++;
            if((lastShowIdx+i) > godReviewlist.length-1){
                lastShowIdx = 0;
                i = 0;
            }
            $("#"+godReviewTabId).children("li:eq("+(lastShowIdx+i)+")").show();
        }
        lazyLoad.init();
    }
}

// #174094 [SSF] MO_2022_브랜드 추천 START
/**
 * [SSF] MO_2022_브랜드 추천
 * [button] 다른 브랜드 보기(랜덤으로 돌림)
 * 사이클 단위로 랜덤 노출 시키기 위해 리스트 복사해서 사용
 * @returns {boolean}
 */

//1. [다른 브랜드 보기]
var brndRecoCount = 0;
function showRandomBrndReco(){
    //브랜드 추천  브랜드가 없는 경우에는 안보이도록 처리
    if(brndRecoList.length < 1){
        $(".recomSet-Brand").hide();
        return false;
    }

    //브랜드 노출 순서 셔플
    if(brndRecoShuffleList.length < 1){
        shuffleBrnd();
    }
    var currentNo = (brndRecoCount++) % brndRecoShuffleList.length;
    var brndRecoInfo = brndRecoShuffleList[currentNo];
    var nextBrndRecoInfo = brndRecoShuffleList[currentNo+1];
    if($("#brndRecoGodsList_"+brndRecoInfo.idx).children().find("li").length > 0) {
        showCurrentBrand(brndRecoInfo);
    }else{
        //현재 노출 될 브랜드 상품 조회,이미지 show
        initBrndRecoInfoProductList(brndRecoInfo, true);
    }

    if(typeof nextBrndRecoInfo != "undefined"){
        //다음에 노출 될 브랜드 상품 조회
        initBrndRecoInfoProductList(nextBrndRecoInfo, false);
    }

}
//1.2
function shuffleBrnd(){
    var brndRecoListCopy = [];
    //브랜드 리스트 복사
    if(brndRecoListCopy.length < 1){
        brndRecoListCopy = brndRecoList.slice();
        brndRecoShuffleList = [];
    }
    var len = brndRecoListCopy.length;
    for(var j=0; j < len; j++) {
        var randomIdx = Math.floor(Math.random() * brndRecoListCopy.length);
        var showBrndInfo = brndRecoListCopy.splice(randomIdx, 1);
        brndRecoShuffleList.push(showBrndInfo[0]);
    }
}

//2. 상품 리스트 조회, 이미지 처리
function initBrndRecoInfoProductList(brndRecoInfo, current){
    if(document.querySelector("#brndRecoGodsList_" + brndRecoInfo.idx + " .godList") == null){
        $.ajax({
            type: "get",
            url: "/selectBrandGodList",
            data: {brndShopId: brndRecoInfo.brndShopId, cnrSetSn: $("#recoBrndCnrSetSn").val()},
            async: true,
            beforeSend:function(){
                //	$(".page-loding").show();
            },
            complete:function(){
                //	$(".page-loding").hide();
            },
            success: function (data) {
                $("#brndRecoGodsList_"+brndRecoInfo.idx).html(data);
                if($("#brndRecoGodsList_"+brndRecoInfo.idx).children().find("li").length > 0){
                    if(current){
                        showCurrentBrand(brndRecoInfo);
                    }
                }else{
                    brndRecoList = brndRecoList.filter(function (brnd) {
                        return brnd.idx != brndRecoInfo.idx;
                    });
                    brndRecoShuffleList = brndRecoShuffleList.filter(function (brnd) {
                        return brnd.idx != brndRecoInfo.idx;
                    });
                    if(current){
                        brndRecoCount--;
                        showRandomBrndReco();
                    }
                }

            },
            error: function () {
            }
        });
    }else if(current){
        showCurrentBrand(brndRecoInfo);
    }
}

//브랜드 show
function showCurrentBrand(brndRecoInfo){
    $("[class^='brndGrp_']").hide();
    $(".brndGrp_" + brndRecoInfo.idx).show();
    $("#btnBrndReload").show();
    lazyLoad.init();
}

/**
 * [SSF] MO_2022_브랜드 추천
 * [button] 브랜드 찜하기
 * @param obj
 */
function main_clickMyBrand(obj){
    let brndId = $(obj).attr("like");
    let check = $(obj).attr("aria-pressed") == "true"? 'delete':'insert';
    main_updateMyBrnd(
        {
            check : check,
            brndId : brndId,
            noti : false
        }
    );
}

// [SSF] MO_2022_키워드(상품노출형)
function keywordGodMore(cnrDscr,cnrSetSn,_this) {
    var tabCount = $(_this).find("input[name='tabCount']").val();
    var mainPageNm = $("input[name='mainPageNm']").val();

    $.ajax({
        type : "GET",
        url : "/getTabDataMore",
        data : {"cnrDscr" : cnrDscr, "cnrSetSn" : cnrSetSn, "tabCount" : tabCount, "tmplatScKey" : mainPageNm},
        success : function (data) {
            $("#keywordGodList").empty();
            $("#keywordGodList").append(data);

            $(_this).attr("aria-selected","true");
            $(_this).siblings().attr("aria-selected","false");

            lazyLoad.init();
        }
    });
}

/**
 * [SSF] MO_2022_브랜드 추천
 * 마이브랜드 등록/삭제 처리
 * @param param
 * @param callback
 */
function main_updateMyBrnd( param ){
    if(!window.givenOption.isUser){
        commonConfirm2(window.mem_msg_require_login_confirm, function(){
            var cur = location.href;
            window.location.href = "/public/member/login?loginTarget="+encodeURIComponent(cur);
        });
        event.stopImmediatePropagation();
        return;
    }
    let { check, brndId, noti } = param;
    if( !brndId ){gnbKeyword
        return;
    }
    $.ajax({
        type : "POST",
        url : check === 'delete'?'/api/v1/display/brand/deleteMyBrand':'/api/v2/display/brand/addMyBrand',
        beforeSend : function(request){
            if( check === 'delete'){
                request.setRequestHeader("X-API-VERSION", "1.0.1");
            } else {
                request.setRequestHeader("X-API-VERSION", "2.0.0");
            }
        },
        data : {brndId : brndId},
        async : false,
        success: function (result) {
            if( !result || !result.status || result.status != 'success'){
                return;
            }

            // 호출측에 변경이 필요할때 callbak 처리
            if( check === 'delete'){
                $("[like='"+brndId+"']").attr("aria-pressed","false");
            } else {
                $("[like='"+brndId+"']").attr("aria-pressed","true");
            }

            if( noti ){
                if( check === 'delete' ){
                    notiToast("위시리스트에서\n해제되었습니다.", {type: 'favorite'});
                } else {
                    notiToast("위시리스트에\n담겼습니다.", {type: 'favorite'});
                }
            }
        }
    });
    event.stopImmediatePropagation();
}

function cnrClickLoging(tp,set,dpos,target){
    var tagPage = $("input[name='logTagPage']").val();

    action_logging({'tagPage':tagPage,'tagArea':'cornner','tp':tp,'set':set,'dpos':dpos,'target':target});
}

function setMovMuteControl() {
    $('.movTxtBanner').each(function(){
        var $this = $(this);

        if ($this.find('.type-video').length) {
            if ($this.hasClass('initialized')) return;

            $this.on('click', '.type-video .mute', function(){
                var video = $(this).parent().find('video');
                video.get(0).muted = !video.get(0).muted;
                if (video.get(0).muted) {
                    $(this).attr('aria-pressed', true)
                }
                else {
                    $(this).attr('aria-pressed', false);
                    video.get(0).volume = 0.2;
                }
            });

            $this.addClass('initialized');
        }

    });
}

$(document).ready(function(){
  $('li[class="lazy-tab"]').click(function(e){
    var me = $("#"+$(this).attr("img-con-id"));
    me.find('img[class="lazy-img"]').each(function () {
      var that = $(this);
      if( that.attr("original-img") ){
        that.attr("src", that.attr("original-img"));
        that.removeAttr("original-img");
      }
    });
  });

    // [SSF]  MO_2022_브랜드 룩북
    var lookbookSetSimpleOption = {
        slidesPerView: 'auto',
        spaceBetween: 15,
        loop:false,
        pagination: {
            el: ".swiper-pagination",
            type: "progressbar"
        },
        on: {
            init: function(swiper){
                lazyLoadSwiper.init(this);
            }
        },
        watchSlidesProgress:true
    }

    $(".lookbookSet .swiper").each(function(idx){
        lookbookSet[idx] = new Swiper(this, lookbookSetSimpleOption);

        $("*[role='listbox']", $(this).parent()).on('click', 'li', function(e){
            // ajax data 호출 시,
            var el = $(this).parents('section').find('.swiper');

            var cnrSetSn = $(this).find("input[name='cnrSetSn']").val();
            //var cnrDscr = $("section[name='brandLookbook']").find("input[name='cnrDscr']").val();
            var cnrDscr = $(this).find("input[name='dspCnrDscr']").val();
            var tabCount = parseInt($(this).find("input[name='tabCount']").val()) + 1;
            var _this = $(this);
            var mainPageNm = $("input[name='mainPageNm']").val();

            $.ajax({
                type : "GET",
                url : "/getTabDataMore",
                data : {"cnrSetSn" : cnrSetSn, "cnrDscr" : cnrDscr, "tabCount" : tabCount, "tmplatScKey" : mainPageNm},
                success : function (data) {
                    lookbookSet[idx].destroy(); //data insert 전, slide 초기화
                    $("#lookbookList"+cnrDscr).empty();
                    $("#lookbookList"+cnrDscr).html(data);

                    $(_this).attr("aria-selected","true");
                    $(_this).siblings().attr("aria-selected","false");

                    lookbookSet[idx] = new Swiper(el[0], lookbookSetSimpleOption); // data insert 후, slide 호출
                }
            });

        });
    });

    // [SSF] MO_2022_혜택 띠배너
    $(".sashBanner .swiper").each(function(idx){
        var $this = $(this);
        var slide_len = $(".swiper-slide", this).length;
        var loop, pagination;

        if(slide_len > 1){
            loop = true;
            $(".swiper-slide span", this).css('opacity', 0);
            pagination = {
                el: ".swiper-pagination",
                type: "fraction"
            }
        }else{
            loop = false;
            pagination = false;
        }

        sashBanner[idx] = new Swiper(this, {
            //effect:'fade',
            loop:loop,
            autoplay:{
                delay: 3500,
                disableOnInteraction:false
            },pagination: pagination,
            on:{
                'init':function(swiper){
                    $this.on('click', 'button.autoplay', function(){
                        if($(this).attr('aria-label') == "stop"){
                            $(this).attr('aria-label', 'play');
                            swiper.autoplay.stop();
                        }else{
                            $(this).attr('aria-label', 'stop');
                            swiper.autoplay.start();
                        }
                    });

                    lazyLoadSwiper.init(this);
                }
            }
        })
    });

    // [SSF] MO_2022_BRAND(BI LOGO)
    var hotBrands = new Swiper(".hotBrands .swiper", {
        loop: false,
        pagination : {
            el: ".swiper-pagination",
            type: "progressbar"
        },
        on: {
            init:function(swiper){
                lazyLoadSwiper.init(this);
            }
        }
    });

    // [SSF] MO_2022_상품리스트(더보기) 01-04
    var categoryBrandSetOption = {
        slidesPerView: 'auto',
        spaceBetween: 15,
        loop:false,
        pagination: {
            el: ".swiper-pagination",
            type: "progressbar"
        },
        on: {
            init: function(swiper){
                lazyLoadSwiper.init(this);
            }
        },
        watchSlidesProgress:true
    }

    $(".categorySet-brand .swiper").each(function(idx){
        categoryBrandSet[idx] = new Swiper(this, categoryBrandSetOption);
    });

    // [SSF] MO_2022_상품리스트(세로형)
    if($('section[id*=godListD_]').length > 0){
        $('section[id*=godListD_]').each(function(index,item){
            var tabIdx = $(item).find("li[aria-selected='true']").index();
            
            if(tabIdx != -1){
                var godList = $(item).find(".godList").eq(tabIdx);
                godList.siblings("ul").css("display","none");
            }
        });
    }

    $('[id*=godListD_] .tabC li').on("click",function (){
        var index = $(this).index();
        var godList = $(this).parents().siblings('ul').eq(index);
        
        $(this).attr("aria-selected","true");
        $(this).siblings().attr("aria-selected","false");

        godList.css("display","");
        godList.siblings("ul").css("display","none");
    });


    var promotionSetSlide = [];

    //ajax 로 호출할 경우, 카드 정렬을 위해  cardMasonry_align() 호출
    $(".picksSet-List .swiper").each(function(idx){
        var loop, pagination;
        if($(".swiper-slide", this).length > 1){
            loop=true;
            pagination ={
                el: ".swiper-pagination",
                type: "progressbar"
            }
        }else{
            loop=false;
            pagination=false
        }
        promotionSetSlide[idx] = new Swiper(this, {
            slidesPerView: 1,
            spaceBetween: 0,
            loop:false,
            pagination:pagination,
            on: {
                transitionStart: function(swiper){
                    try{
                        allVideos_Pause($(swiper.$el[0]));
                    }catch (ignore){
                        console.log(ignore);
                    }
                },transitionEnd: function(swiper){
                    try{
                        //videoinSlide_Play($(swiper.$el[0]));
                        videoinSlide_Play($(swiper.slides[swiper.activeIndex])); /*//220830*/
                    } catch (ignore){
                        console.log(ignore);
                    }
                },init: function(swiper){
                    lazyLoadSwiper.init(this);
                }
            },
            preloadImages: true
        });
    });

    if($(".picksSet-Full .swiper .swiper-slide").length > 0 ) {
        var promotionSetFull = new Swiper(".picksSet-Full .swiper", {
            slidesPerView: "auto",
            centeredSlides: false,
            spaceBetween: 15,
            loop:false,
            pagination: {
                el: ".swiper-pagination",
                type: "progressbar"
            }/*동영상 미노출로 변경됨,on: {
                transitionStart: function(swiper){
                    allVideos_Pause($(swiper.$el[0]));
                },transitionEnd: function(swiper){
                    videoinSlide_Play($(swiper.$el[0]))
                }
            }*/
            ,
            preloadImages: true,
            on: {
                init: function(swiper){
                    lazyLoadSwiper.init(this);
                }
            }
        });
    }

    /*[SSF] MO_2022_동영상 배너*/
    setMovMuteControl();

    if($('#newInNEW_CTGType').length > 0){
        setTimeout(function(){
            setNewinSwiper();
        },400);

        // 함수명 임의 지정
        function setNewinSwiper(){
            let $this = $('#newInNEW_CTGType');
            if (!$this.length) return;
            let tab = $this.find('.tabC[role=listbox]');
            let tabs = tab.find('[role=option]');
            let wrapper = $this.find('.swiper-wrapper');
            let length = tabs.length;
            let slides = (function() {
                for (let i = 0; i < length; i++) {
                    if(tabs[i].getAttribute('ctgryNo') == null || tabs[i].getAttribute('ctgryNo') == ""){
                        wrapper.append(`<div class="swiper-slide" data-ctgryno="All"></div>`);
                    }else{
                        wrapper.append(`<div class="swiper-slide" data-ctgryno="${tabs[i].getAttribute('ctgryNo')}"></div>`);
                    }
                }

                return wrapper.find('.swiper-slide');
            })();

            var swiper = new Swiper($this[0].querySelector('.swiper'), {
                slidesPerView: 1,
                autoHeight: true, // #211705:20230728
                // loop: true, //순환 하지 않는 것으로 협의됨.
                on: {
                    init: function (){
                        lazyLoadSwiper.init(this);
                    },
                    transitionEnd: function(swiper) {
                        let nextIndex = swiper.activeIndex + 1;
                        try {
                            exe_listOption(tab, tabs[swiper.activeIndex]);
                            getLoadSlides(swiper.activeIndex);
                        }
                        catch(e) {}
                    }
                }
            });

            getLoadSlides(0);

            // 탭 클릭 시 처리
            tabs.on('click', function(e){
                e.preventDefault();

                let index = $(this).index();

                getLoadSlides(index);
                swiper.slideTo(index, 0);
});

            function getLoadSlides(index) {

                // 현재
                setting(index);

                // 이전
                setting(index - 1);

                // 다음
                setting(index + 1);

                function setting(i) {
                    try {
                        if (slides[i] && !slides[i].classList.contains('done')) {
                            getCateItemList(i);
                            slides[i].classList.add('done');
                        }
                    }
                    catch(e) {}
                }
            }

            function getCateItemList(index) {

                // ctgryNo 사용 가능
                let ctgryNo = tabs[index].getAttribute('ctgryNo');
                let ctgryNm = tabs[index].innerText;

                $.ajax({
                    type: "GET",
                    url: "/newInCorner",
                    data: {
                        "brndId": givenMain.brndId,
                        "dspCtgryNo": ctgryNo,
                        "imgTurn": $("#newInNEW_CTGType").attr("imgTurn"),
                        isFilter: true,
                        "line": 5,
                        "ctgrySectCd": givenMain.dspCtgryInfoDspCtgryCtgrySectCd,
                        "dscr": "newInNEW_CTGType"
                    },
                    async: true,
                    success: function (data) {
                            var html = "";
                            html += "<ul class='godList column3' id='newin-godList"+ctgryNo+"'>"
                            html += "<input type='hidden' name='newinLoadYn' value='Y' />"
                            html += "<h4 class='wa_hidden'>"+ givenMain.mallmain_corner_newIn_title_all +"</h4>";
                            html += data;
                            html += "</ul>";

                            if(ctgryNo == null || ctgryNo == ""){
                                wrapper.find(`[data-ctgryno=All]`).append(html);
                            }else {
                                wrapper.find(`[data-ctgryno=${ctgryNo}]`).append(html);
                            }
                            lazyLoadSwiper.init(swiper);

                            // #211705:20230728
                            swiper.updateAutoHeight(0);
                    },
                    error : function(jqXHR, textStatus, errorThrown) {
                        console.log(textStatus)
                    }
                });
            }
        }
    }

    if($('#rankingCLICK_RANK_CTGType').length > 0){
        setTimeout(function() {
            if($("#dspCtgryListCLICK_RANK_CTGType").val() != '' && $("#dspCtgryListCLICK_RANK_CTGType").val() != null) { //bo에 세팅된 카테고리 있어야만 스와이프 실행
                setClickRankSwiper();
            }
        }, 500);

        function setClickRankSwiper(){
            let $this = $('#rankingCLICK_RANK_CTGType');
            if (!$this.length) return;
            let tab = $this.find('.tabC[role=listbox]');
            let tabs = tab.find('[role=option]');
            let wrapper = $this.find('.swiper-wrapper');
            let length = tabs.length;
            let slides = (function() {
                for (let i = 0; i < length; i++) {
                    if(tabs[i].getAttribute('ctgryNo') == null || tabs[i].getAttribute('ctgryNo') == ""){
                        wrapper.append(`<div class="swiper-slide" data-ctgryno="All"></div>`);
                    }else{
                        wrapper.append(`<div class="swiper-slide" data-ctgryno="${tabs[i].getAttribute('ctgryNo')}"></div>`);
                    }
                }

                return wrapper.find('.swiper-slide');
            })();
            var swiper = new Swiper($this[0].querySelector('.swiper'), {
                slidesPerView: 1,
                autoHeight: true, // #211705:20230728
                // loop: true, //순환 하지 않는 것으로 협의됨.
                on: {
                    init: function (){
                        lazyLoadSwiper.init(this);
                    },
                    transitionEnd: function(swiper) {
                        let nextIndex = swiper.activeIndex + 1;
                        try {
                            exe_listOption(tab, tabs[swiper.activeIndex]);
                            getLoadSlides(swiper.activeIndex);
                        }
                        catch(e) {}
                    }
                }
            });

            //getLoadSlides(0);

            // 탭 클릭 시 처리
            tabs.on('click', function(e){
                e.preventDefault();

                let index = $(this).index();

                getLoadSlides(index);
                swiper.slideTo(index, 0);
            });
            setMainObserver("rankingCLICK_RANK_CTGType");
            function getLoadSlides(index) {

                // 현재
                setting(index);

                // 이전
                setting(index - 1);

                // 다음
                setting(index + 1);

                function setting(i) {
                    try {
                        if (slides[i] && !slides[i].classList.contains('done')) {
                            getCateItemList(i);
                            slides[i].classList.add('done');
                        }
                    }
                    catch(e) {}
                }
                if(index == 0 ){
                    $("#ranking-navCLICK_RANK_CTGType > li").eq(0).attr("aria-selected","true").siblings().attr("aria-selected","false");
                }

            }

            function getCateItemList(index) {

                let ctgryNo = tabs[index].getAttribute('ctgryNo');
                let cnrType = "javascript:rankingViewMore('CLICK_RANK_CTGType')";
                $.ajax({
                    type: "GET",
                    url: "/rankingCorner",
                    data: getRankingParam("CLICK_RANK_CTGType", ctgryNo),
                    async: true,
                    success: function (data) {
                        var html = "<ul class='godList column3' id='ranking-godList" + ctgryNo + "'>";
                        html += data;
                        html += "</ul>";
                        html += "<a href='javascript:void(0);' class='go' onClick="+cnrType+">랭킹 바로가기</a>";
                        if(ctgryNo == null || ctgryNo == ""){
                            wrapper.find(`[data-ctgryno=All]`).append(html);
                        }else {
                            wrapper.find(`[data-ctgryno=${ctgryNo}]`).append(html);
                        }

                        lazyLoadSwiper.init(swiper);

                        // #211705:20230728
                        swiper.updateAutoHeight(0);
                    },
                    error : function(jqXHR, textStatus, errorThrown) {
                        console.log(textStatus)
                    }
                });
            }
        }
    }

    if($('#rankingSALE_RANK_CTGType').length > 0){
        setTimeout(function() {
            if($("#dspCtgryListSALE_RANK_CTGType").val() != '' && $("#dspCtgryListSALE_RANK_CTGType").val() != null) { //bo에 세팅된 카테고리 있어야만 스와이프 실행
                setSaleRankSwiper();
            }
        }, 500);

        function setSaleRankSwiper(){
            let $this = $('#rankingSALE_RANK_CTGType');
            if (!$this.length) return;
            let tab = $this.find('.tabC[role=listbox]');
            let tabs = tab.find('[role=option]');
            let wrapper = $this.find('.swiper-wrapper');
            let length = tabs.length;
            let slides = (function() {
                for (let i = 0; i < length; i++) {
                    if(tabs[i].getAttribute('ctgryNo') == null || tabs[i].getAttribute('ctgryNo') == ""){
                        wrapper.append(`<div class="swiper-slide" data-ctgryno="All"></div>`);
                    }else{
                        wrapper.append(`<div class="swiper-slide" data-ctgryno="${tabs[i].getAttribute('ctgryNo')}"></div>`);
                    }
                }

                return wrapper.find('.swiper-slide');
            })();

            var swiper = new Swiper($this[0].querySelector('.swiper'), {
                slidesPerView: 1,
                autoHeight: true, // #211705:20230728
                // loop: true, //순환 하지 않는 것으로 협의됨.
                on: {
                    init: function (){
                        lazyLoadSwiper.init(this);
                    },
                    transitionEnd: function(swiper) {
                        let nextIndex = swiper.activeIndex + 1;
                        try {
                            exe_listOption(tab, tabs[swiper.activeIndex]);
                            getLoadSlides(swiper.activeIndex);
                        }
                        catch(e) {}
                    }
                }
            });

            //getLoadSlides(0);

            // 탭 클릭 시 처리
            tabs.on('click', function(e){
                e.preventDefault();

                let index = $(this).index();

                getLoadSlides(index);
                swiper.slideTo(index, 0);
            });

            setMainObserver("rankingSALE_RANK_CTGType");

            function getLoadSlides(index) {

                // 현재
                setting(index);

                // 이전
                setting(index - 1);

                // 다음
                setting(index + 1);

                function setting(i) {
                    try {
                        if (slides[i] && !slides[i].classList.contains('done')) {
                            getCateItemList(i);
                            slides[i].classList.add('done');
                        }
                    }
                    catch(e) {}
                }
                if(index == 0 ){
                    $("#ranking-navSALE_RANK_CTGType > li").eq(0).attr("aria-selected","true").siblings().attr("aria-selected","false");
                }

            }

            function getCateItemList(index) {

                let ctgryNo = tabs[index].getAttribute('ctgryNo');
                let cnrType = "javascript:rankingViewMore('SALE_RANK_CTGType')";
                $.ajax({
                    type: "GET",
                    url: "/rankingCorner",
                    data: getRankingParam("SALE_RANK_CTGType", ctgryNo),
                    async: true,
                    success: function (data) {
                        var html = "<ul class='godList column3' id='ranking-godList" + ctgryNo + "'>";
                        html += data;
                        html += "</ul>";
                        html += "<a href='javascript:void(0);' class='go' onClick="+cnrType+">랭킹 바로가기</a>";
                        if(ctgryNo == null || ctgryNo == ""){
                            wrapper.find(`[data-ctgryno=All]`).append(html);
                        }else {
                            wrapper.find(`[data-ctgryno=${ctgryNo}]`).append(html);
                        }

                        lazyLoadSwiper.init(swiper);

                        // #211705:20230728
                        swiper.updateAutoHeight(0);
                    },
                    error : function(jqXHR, textStatus, errorThrown) {
                        console.log(textStatus)
                    }

                });
            }
        }
    }

    if($('#sectionBestReview').length > 0){
        setBestReviewSwiper();
    }

    function setBestReviewSwiper(){
        let $this = $('#sectionBestReview');
        if (!$this.length) return;
        let tab = $this.find('.tabC[role=listbox]');
        let tabs = tab.find('[role=option]');
        let wrapper = $this.find('.swiper-wrapper');
        var swiper = new Swiper($this[0].querySelector('.swiper'), {
            slidesPerView: 1,
            //autoHeight: true, // #211705:20230728
            // loop: true, //순환 하지 않는 것으로 협의됨.
            on: {
                init: function (){
                    lazyLoadSwiper.init(this);
                },
                transitionEnd: function(swiper) {
                    let nextIndex = swiper.activeIndex + 1;
                    try {
                        exe_listOption(tab, tabs[swiper.activeIndex]);
                    }
                    catch(e) {}
                }
            }
        });

        // 탭 클릭 시 처리
        tabs.on('click', function(e){
            e.preventDefault();
            let index = $(this).index();
            swiper.slideTo(index, 0);
        });
    }

    if($('#8S_RANK').length > 0){
        setRankingSwiper();
        function setRankingSwiper() {
            var type ="8S_RANK";
            let $8sSection = $("#" + type);
            let $8sNav = $("#best-ranking-nav");
            let $this = $('#8S_RANK');
            if (!$this.length) return;
            let tab = $this.find('.tabC[role=listbox]');
            let tabs = tab.find('[role=option]');
            let wrapper = $this.find('.swiper-wrapper');
            let length = tabs.length;
            let slides = (function () {
                for (let i = 0; i < length; i++) {
                    if(tabs[i].getAttribute('ctgryNo') == null || tabs[i].getAttribute('ctgryNo') == ""){
                        wrapper.append(`<div class="swiper-slide" data-ctgryno="All"></div>`);
                    }else{
                        wrapper.append(`<div class="swiper-slide" data-ctgryno="${tabs[i].getAttribute('ctgryNo')}"></div>`);
                    }
                }

                return wrapper.find('.swiper-slide');
            })();

            var swiper = new Swiper($this[0].querySelector('.swiper'), {
                slidesPerView: 1,
                autoHeight: true, // #211705:20230728
                // loop: true, //순환 하지 않는 것으로 협의됨.
                on: {
                    init: function () {
                        lazyLoadSwiper.init(this);
                    },
                    transitionEnd: function (swiper) {
                        let nextIndex = swiper.activeIndex + 1;
                        try {
                            exe_listOption(tab, tabs[swiper.activeIndex]);
                            getLoadSlides(swiper.activeIndex);
                        } catch (e) {
                        }
                    }
                }
            });

            getLoadSlides(0);

            // 탭 클릭 시 처리
            tabs.on('click', function (e) {
                e.preventDefault();

                let index = $(this).index();

                getLoadSlides(index);
                swiper.slideTo(index, 0);
            });

            function getLoadSlides(index) {

                // 현재
                setting(index);

                // 이전
                setting(index - 1);

                // 다음
                setting(index + 1);

                function setting(i) {
                    try {
                        if (slides[i] && !slides[i].classList.contains('done')) {
                            getCateItemList(i);
                            slides[i].classList.add('done');
                        }
                    } catch (e) {
                    }
                }
            }

            function getCateItemList(index) {

                // ctgryNo 사용 가능
                let ctgryNo = null;
                ctgryNo = tabs[index].getAttribute('ctgryNo');
                let ctgryNm = tabs[index].innerText;
                let ctgryFlterCd = tabs[index].getAttribute('ctgryFlterCd');
                $.ajax({
                    type: "GET",
                    url: "/rankingCorner",
                    data: getRankingParam(type, ctgryNo),
                    async: true,
                    success: function (data) {
                        var html = data;
                        if (ctgryNo == "" || ctgryNo == null) {
                            wrapper.find(`[data-ctgryno=All]`).append(html);
                        } else {
                            wrapper.find('[data-ctgryno='+ctgryNo+']').append(html);
                        }

                        let showCtryNm = $("#" + type).find("input[name='showCtryNm']").val();

                        if (showCtryNm != null && showCtryNm != "") {
                            if (showCtryNm.indexOf("CTGRY_ALL") < 0) {
                                $8sSection.remove();
                            } else {
                                $8sSection.find("#best-ranking-nav > li").each(function () {
                                    if (showCtryNm.indexOf($(this).attr("ctgryFlterCd")) == -1) {
                                        $(this).remove();
                                        wrapper.find('[data-ctgryno='+$(this).attr("ctgryNo")+']').remove();
                                    }
                                });

                                let params = getUrlParams();
                                if (!params.ctgryFlterCd) {
                                    $($($8sNav.find('li')).get(randomArrayIndex($8sNav.find('li').length))).trigger('click');
                                } else {
                                    let ctgryFlterCd = params.ctgryFlterCd || "CTGRY_ALL";
                                    $8sNav.find("li[ctgryfltercd='" + ctgryFlterCd + "']").trigger("click");
                                }
                            }
                        }
                        lazyLoadSwiper.init(swiper);

                        // #211705:20230728
                        swiper.updateAutoHeight(0);
                        set_scrollX_po();
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                    }
                });
            }
        }
    }

    if ($(".main-tabs li.selected").length < 1) {
        $(".main-tabs li").eq(0).addClass("selected");
    }

    /*[SSF] MO_2022_상품리뷰코너*/
    try{
        $("#sectionBestReview").show();
        //로드시 0번 탭 선택
        $($("li[godReviewTabId^='review_']")[0]).children("a").click();
        //#179375 카테고리 영역 스크롤
        set_scrollX_po();
    }catch (e) {
        console.log(e);
    }
    // #174094[SSF] MO_2022_상품리뷰코너 관련 js END

    /*[SSF] MO_2022_브랜드*/
    try{
        // 브랜드 추천 상품리스트 조회 (화면 로드시 모든 브랜드 상품 조회)
        if(typeof(brndRecoList) != "undefined"){
            // initBrndRecoProductList(brndRecoList);
            showRandomBrndReco();
        }
    }catch (e) {
        console.log("brndReco error::", e);
    }
    //<!-- #174094 [SSF] MO_2022_브랜드 추천 END -->

    /* MO_2024_카테고리 바로가기2 */
    if($('#sectionGotoCategorySlide').length > 0){
        ssf.main.contents.swiper("gotoCategorySlide");
    }



});

//#210579 lazyload 호출 처리(다이버스타일, 클릭랭킹 코너)
function setMainObserver(type){
    const mainObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                if(entry.target.id == "rankingCLICK_RANK_CTGType"){
                    //클릭랭킹
                    if ($("#rankingCLICK_RANK_CTGType").length > 0 ) {
                        $("#ranking-navCLICK_RANK_CTGType > li").eq(0).click();
                    }
                }else if(entry.target.id == "rankingSALE_RANK_CTGType"){
                    //판매랭킹
                    if ($("#rankingSALE_RANK_CTGType").length > 0 ) {
                        $("#ranking-navSALE_RANK_CTGType > li").eq(0).click();
                    }
                }else if(entry.target.id == "diverDIVER_STYLE_BANNER"){
                    //다이버스타일
                    if ($("#diverDIVER_STYLE_BANNER").length > 0) {
                        var tabInit = 0;
                        var tabCheck = "N";
                        if(tabCheck == 'N' && $("#diverDIVER_STYLE_BANNER .tabB li").length > 0){
                            tabInit=Math.floor(Math.random() * $("#diverDIVER_STYLE_BANNER .tabB li").length);
                            $("#diverDIVER_STYLE_BANNER .tabB li").eq(tabInit).click();
                        }
                    }
                }
                observer.unobserve(entry.target);
            }
        });
    }, {rootMargin: '150% 0px 0px 0px', threshold: [0, 0.3, 1]});

    if(type == "rankingCLICK_RANK_CTGType"){
        mainObserver.observe(document.getElementById("rankingCLICK_RANK_CTGType"));
    }else if(type == "rankingSALE_RANK_CTGType"){
        mainObserver.observe(document.getElementById("rankingSALE_RANK_CTGType"));
    }else if(type == "diverDIVER_STYLE_BANNER"){
        mainObserver.observe(document.getElementById("diverDIVER_STYLE_BANNER"));
    }
}

    var keywordTag = [];
    var categoryHorizontal = [];
    var ssfShopNowImages = [];
    var ssfShopNowCaptions = [];

    $(document).ready(function(){

        /* [SSF] MO_2022_대배너 */
        /* [SSF] MO_2022_브랜드 대배너 */
        /* //#226243: 다수 swiper 적용을 위한 스크립트 수정 */
        ssf.main.corner.init();

        var keywordTagFullOption = {
            slidesPerView: 'auto',
            spaceBetween: 15,
            loop:false,
            pagination: {
                el: ".swiper-pagination",
                type: "progressbar"
            },
            watchSlidesProgress:true,
            preloadImages: true,
            /*lazy: {
                loadPrevNext: true,
                loadPrevNextAmount: 3,
            }*/
            on: {
                init:function(swiper){
                    lazyLoadSwiper.init(this);
                }
            }
        }

        $(".keywordTag .swiper").each(function(idx){
            var $wrap =  $(this).parent();

            keywordTag[idx] = new Swiper(this, keywordTagFullOption);

            $("*[role='listbox']", $(this).parent()).on('click', 'li', function(e){
                // ajax data 호출 시,
                var el = $(this).parents('section').find('.swiper');
                keywordTag[idx].destroy(); //data insert 전, slide 초기화
                keywordTag[idx] = new Swiper(el[0], keywordTagFullOption); // data insert 후, slide 호출
            });
        });
        // [SSF] MO_2022_키워드(상품노출형)

        // [SSF] MO_2022_탭 상품 리스트(가로형)
        var categoryHorizontalOption = {
            slidesPerView: 'auto',
            spaceBetween: 15,
            loop:false,
            pagination: {
                el: ".swiper-pagination",
                type: "progressbar"
            },
            watchSlidesProgress:true
            ,on: {
                init:function(swiper){
                    lazyLoadSwiper.init(this);
                }
            }
        }

        $(".categorySet-Horizontal .swiper").each(function(idx){
            categoryHorizontal[idx] = new Swiper(this, categoryHorizontalOption);

            $("*[role='listbox']", $(this).parent()).on('click', '> *', function(e){
                // ajax data 호출 시,
                var el = $(this).parents('section').find('.swiper');
                categoryHorizontal[idx].destroy(); //data insert 전, slide 초기화
                categoryHorizontal[idx] = new Swiper(el[$(this).index()], categoryHorizontalOption); // data insert 후, slide 호출

                el.hide();
                el.eq($(this).index()).show();
            });
        });
        // [SSF] MO_2022_탭 상품 리스트(가로형)

        /* 프로모션/이벤트 코너 */
        $(".ssfShopNow .swiper.captions").each(function(idx){
            var $this = $(this);
            var slide_len = $(".swiper-slide", this).length;

            if(slide_len > 1){
                loop = true;
            }else{
                loop = false;
            }

            ssfShopNowCaptions[idx] = new Swiper(this, {
                effect:'fade',
                loop:loop,
                // #173386:20220929
                on: {
                    init:function(swiper){
                        lazyLoadSwiper.init(this);
                    }
                }
            });
        });


        $(".ssfShopNow .swiper.imgs").each(function(idx){
            var $this = $(this);
            var slide_len = $(".swiper-slide", this).length;
            var loop, pagination;

            if(slide_len > 1){
                loop = true;
                pagination = {
                    el: ".swiper-pagination",
                    type: "fraction"
                }
            }else{
                loop = false;
                pagination = false;
                $(".controls", this).hide();
            }

            var controls_align = function(){
                $(".ssfShopNow .controls").css({
                    top: ($(".ssfShopNow .swiper-slide .img").height() - $(".ssfShopNow .controls").outerHeight() - 6)+"px"
                });
            }

            ssfShopNowImages[idx] = new Swiper(this, {
                loop:loop,
                autoplay:{
                    delay: 3500,
                    disableOnInteraction:false/*//20220826*/
                },
                pagination: pagination,
                on:{
                    'init':function(swiper){

                        // #173386:20220929
                        lazyLoadSwiper.init(this);

                        $this.on('click', 'button.autoplay', function(){
                            if($(this).attr('aria-label') == "stop"){
                                $(this).attr('aria-label', 'play');
                                swiper.autoplay.stop();
                            }else{
                                $(this).attr('aria-label', 'stop');
                                swiper.autoplay.start();
                            }
                        });

                        controls_align();

                        $(window).on('resize', function(){
                            controls_align();
                        })
                    },/*, 동영상 미노출로 변경됨
                                transitionStart: function(swiper){
                                    allVideos_Pause($(swiper.$el[0]));
                                },transitionEnd: function(swiper){
                                    videoinSlide_Play($(swiper.$el[0]))
                                }*/
                    transitionEnd: function(swiper){
                        try{
                            ssfShopNowCaptions[idx].slideTo(swiper.activeIndex);
                        } catch (ignore){
                            console.log(ignore);
                        }
                    }
                }
            });
            /* #187038 [MO] 이벤트 모듈 노출 텍스트와 이미지 따로 움직이는 현상 */
            ssfShopNowImages[idx].controller.control = ssfShopNowCaptions[idx]; 
            ssfShopNowCaptions[idx].controller.control = ssfShopNowImages[idx];
        });
        /* //프로모션/이벤트 코너 */

    });


/*#225817 [SSF] MO_2024_타임딜_01, [SSF] MO_2024_위클리 핫딜_01 타이머 세팅*/
var arrData = [];
try{
    if(typeof($("#timeDealDspEndDt").val()) != "undefined"){
        arrData.push("timeDealDspEndDt");
    }
    if(typeof($("#weeklyDealDspEndDt").val()) != "undefined"){
        arrData.push("weeklyDealDspEndDt");

        var todayDate = new Date();
        var a1 = $("#weeklyDealDspEndDt").val();
        var a2 = $("#weeklyDealDspBegDt").val();
        var dealDspEndDt = new Date(parseInt(a1.substring(0,4)),
                                    parseInt(a1.substring(4,6)) - 1,
                                    parseInt(a1.substring(6,8)),
                                    parseInt(a1.substring(8,10)),
                                    parseInt(a1.substring(10,12)),
                                    parseInt(a1.substring(12,14)));
        var dealDspBegDt = new Date(parseInt(a2.substring(0,4)),
                                    parseInt(a2.substring(4,6)) - 1,
                                    parseInt(a2.substring(6,8)),
                                    parseInt(a2.substring(8,10)),
                                    parseInt(a2.substring(10,12)),
                                    parseInt(a2.substring(12,14)));

        var timeGauge = (todayDate - dealDspBegDt) / (dealDspEndDt - dealDspBegDt);
        $("#weeklyGauge").attr("style", "width:" + timeGauge*100 + "%");
    }

} catch(err) {}

if(arrData.length > 0){
    $.ajax({
       type : 'GET',
       url : '/special/hd/serverTime.json',
       cache: false,
       complete : function(req) {
           try{
               var dateString = req.getResponseHeader('Date');
               var serverTimestamp = new Date(dateString).getTime();

               var objEndDate = {}
               arrData.forEach(function(item){
                   objEndDate['endDate'+item] = new Date(parseInt($("#"+item).val().substring(0, 4), 10),
                       parseInt($("#"+item).val().substring(4, 6), 10) - 1,
                       parseInt($("#"+item).val().substring(6, 8), 10),
                       parseInt($("#"+item).val().substring(8, 10), 10),
                       parseInt($("#"+item).val().substring(10, 12), 10),
                       parseInt($("#"+item).val().substring(12, 14), 10)
                   );
                   var closeTimestamp =  objEndDate['endDate'+item].getTime();
                   timerObj['timer'+item].init(serverTimestamp, closeTimestamp);
               });

           } catch(err) {}
       },
       error: function(pa_data, status, err) {
       }
    });

    var timerObj = {}
    try{
        arrData.forEach(function(item){
            timerObj['timer'+item] = {
               timerInterval : null
               , closeTimestamp : null
               , serverTimestamp : ''
               , init : function(serverTimestamp, closeTimestamp){
                    timerObj['timer'+item].serverTimestamp = serverTimestamp;
                    timerObj['timer'+item].closeTimestamp = closeTimestamp;
                    timerObj['timer'+item].startTimer();
               }
               , startTimer : function(){
                    timerObj['timer'+item].stopTimer();
                    timerObj['timer'+item].setTimer();
                    timerObj['timer'+item].timerInterval = setInterval(timerObj['timer'+item].setTimer,1000);
               }
               , stopTimer : function(){
                   if(timerObj['timer'+item].timerInterval != null){
                       clearInterval(timerObj['timer'+item].timerInterval);
                   }
               }
               , setTimer : function(){
                   var temp = 0;
                   var days = 0;
                   var timedealHours = 0;
                   var hours = 0;
                   var minutes = 0;
                   var seconds = 0;
                   if(timerObj['timer'+item].closeTimestamp <= timerObj['timer'+item].serverTimestamp){
                       timerObj['timer'+item].stopTimer();
                   } else {
                       temp = Math.abs(timerObj['timer'+item].closeTimestamp - timerObj['timer'+item].serverTimestamp) / 1000;
                       days = Math.floor(temp / 86400);
                       timedealHours = Math.floor(temp / 3600);
                       temp -= days * 86400;
                       hours = Math.floor(temp / 3600) % 24;
                       temp -= hours * 3600;
                       minutes = Math.floor(temp / 60) % 60;
                       temp -= minutes * 60;
                       seconds = temp % 60;
                   }
                    if(days > 99){
                       days = 99;
                       hours = 23;
                       minutes = 59;
                       seconds = 59;
                   }

                    if(item == 'timeDealDspEndDt' && timedealHours > 99){
                        timedealHours = 99;
                        hours = 23;
                        minutes = 59;
                        seconds = 59;
                    }

                    if(item == 'timeDealDspEndDt'){
                        if(timedealHours < 100){
                            $("#timeDealDTime").text(('0' + timedealHours).slice(-2)+':'+('0' + minutes).slice(-2)+':'+('0' + seconds).slice(-2));
                        }

                    } else if (item == 'weeklyDealDspEndDt'){
                        if( days < 1){
                            $("#weeklyDealDTime").text(('0' + hours).slice(-2)+':'+('0' + minutes).slice(-2)+':'+('0' + seconds).slice(-2));
                        }else{
                            $("#weeklyDealDTime").text( days + '일 ' + ('0' + hours).slice(-2)+':'+('0' + minutes).slice(-2)+':'+('0' + seconds).slice(-2));
                        }
                    }

                    timerObj['timer'+item].serverTimestamp += 1000;
               }
            }
        })

    } catch(err) {}
}
